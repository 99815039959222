* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  overflow-x: hidden;
}
.App {
  overflow: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scroll::-webkit-scrollbar,
#scroll::-webkit-scrollbar {
  display: none;
}
.howbodi-landing-container {
  justify-content: center;
  background-image: url("./pages/howbodi/assets/bgshape.png");
  /* background-color: #7baeb3ff; */
  padding: 10rem 0;
}


@font-face {
  font-family: Omega-bold;
  src: url('./assets/fonts/omegaflightbold.ttf');
}

.hero-bold-txt {
  font-family: 'Omega-bold' !important;
  /* color: rgb(246, 246, 246); */
}

.hero-ball {
  transform: matrix(-0.93, -0.37, 0.37, -0.93, 0, 0);
  z-index: 999;
  width: 94px;
  height: 94px;
  background: transparent linear-gradient(181deg, #00fe981c 0%, #00fe98 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  position: absolute;
  border-radius: 50%;
  top: 102px;
  /* animation: bubble-anim 1s infinite; */
}

.hero-ball-2 {
  position: absolute;
  border-radius: 50%;
  top: 510px;
  /* left={['0px', '300px', '300px', '1160px']} */
  width: 77px;
  height: 77px;
  transform: matrix(1, -0.07, 0.07, 1, 0, 0);
  background: transparent linear-gradient(181deg, #00fe981c 0%, #00fe98 100%) 0% 0% no-repeat padding-box;
  opacity: 1;
  z-index: 100;
}
/* @keyframes bubble-anim {
  0% {
    transform: translate(0, 0%);
  }
  100% {
    transform: translate(0%, 90vh);
  }
} */

/* Container */
#bubble-container {
  position: absolute;
  height: 300px;
  width: 100%;
  left: 20px;
  top: 20px;
  background: #ff8;
  z-index: 1000;
}

/* Balls */
#redball,
#greenball,
#blueball {
  position: absolute;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  z-index: 10;
}
#redball {
  background: transparent linear-gradient(181deg, #00fe981c 0%, #00fe98 100%) 0% 0% no-repeat padding-box;
}
#greenball {
  background: transparent linear-gradient(181deg, #00fe981c 0%, #00fe98 100%) 0% 0% no-repeat padding-box;
}
#blueball {
  background: transparent linear-gradient(181deg, #00fe981c 0%, #00fe98 100%) 0% 0% no-repeat padding-box;
}

.arrow {
  animation: arrow 0.8s infinite alternate;
}

@keyframes arrow {
  0% {
    scale: 1;
  }
  100% {
    scale: 1.1;
  }
}
