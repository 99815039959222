@font-face {
    font-family: Trenda-bold;
    src: url("../assets/fonts/LatinotypeTrendaBold.woff");
  }
@font-face {
    font-family: Trenda-bolder;
    src: url("../assets/fonts/LatinotypeTrendaBlack.otf");
  }
  
.bold {
    font-family: "Trenda-bold" !important;
}

.bolder {
    font-family: "Trenda-bolder" !important;
}