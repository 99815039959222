@font-face {
  font-family: Trenda-regular;
  src: url("./assets/fonts/LatinotypeTrendaRegular.woff");
}

@font-face {
  font-family: Trenda-bold;
  src: url("./assets/fonts/LatinotypeTrendaBold.woff");
}
@font-face {
  font-family: Trenda-heavy;
  src: url("./assets/fonts/LatinotypeTrendaHeavy.otf");
}

body {
  font-family: "Trenda-regular";
}
