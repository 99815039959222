.card {
    transition: 250ms ease;

    &:hover {
        .overlay {
             display: block;
        }
        .more {
             display: block;
        }
    }

    .overlay {
        display: none;
        transition: 250ms ease;
        height: inherit;
        width: inherit;
        background-color: rgba(0, 0, 0, .5);
    }
    .more {
        transition: 250ms ease;
        display: none;
    }
}
