.li {
    position: relative;
    padding-bottom: 1px;
    margin-block: 1px;
    cursor: pointer;
}

.li:after {
    content: "";
    width: 100%;
    height: 2px;
    background-color: #100120;
    border-radius: 4px;
    position: absolute;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform .25s ease;
}

.li:hover::after {
    transform: scaleX(1);
}