.ab {
    .a {
        transition: 250ms ease;
    }
    .t {
        transition: 250ms ease;
    }
    &:hover {
        .a {
            animation: slideRight 250ms linear forwards;
        }
        .t {
            animation: slideLeft 250ms linear forwards;
        }
    }
}

@keyframes slideRight {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-4.9rem);
    }
}
@keyframes slideLeft {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(1.3rem);
    }
}